<template>
  <div class="register-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container pt-lg-md">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <card
              type="secondary"
              shadow
              header-classes="bg-white pb-1"
              body-classes="px-lg-2 py-lg-4"
              class="border-0"
            >
              <template>
                <div class="text-center text-muted mb-4 h4">
                  <small>Registrer personlig konto</small>
                </div>
                <div v-show="showIframe" id="auth" style="height:fit-content;height:300px"></div>
                <div class="col-12" v-if="!showIframe && !bankIDCanceled">
                  <h4>{{ step == 0 ? 'Hei' : step == 1 ? 'Hei' : step == 2 ? 'Flott' : step == 3 ? 'Siste steg' : step == 4 ? 'Velkommen' : 'Hei' }} {{ regUser.name }}!</h4>
                </div>
                <div class="col-12" v-if="regUser.reason == 'age'">
                  <p>I henhold til Norsk lov, kan vi kun tilby spill til personer over 18 år.</p>
                  <p>Velkommen tilbake til oss!</p>
                </div>

                <div class="col-12" v-if="regUser.reason == 'userExist'">
                  <p>Du har allerede en bruker registrert i vårt system.</p>
                  <p>Vennligst logg inn med mobil/e-post og passord.</p>

                  <div class="text-center">
                    <router-link
                      to="/login"
                      rel="noopener"
                      v-if="!auth"
                      class="btn btn-neutral btn-icon"
                    >
                      <span class="btn-inner--icon">
                        <i class="fa fa-sign-in mr-2"></i>
                      </span>
                      <span class="nav-link-inner--text">Logg inn</span>
                    </router-link>
                  </div>
                </div>

                <div class="col-12" v-if="bankIDCanceled">
                  <p>Autentiseringen med BankID ble avbrutt eller feilet.</p>
                  <p>Skjedde dette ved en feil, vennligst prøv igjen.</p>

                  <div class="text-center">
                    <base-button
                      tag="a"
                      @click="loadBankID"
                      class="mb-3 mb-sm-0 text-white"
                      type="success"
                      icon="fa fa-user-plus"
                    >Prøv på nytt</base-button>
                  </div>
                </div>

                <!-- Step 1 -->
                <div class="col-12" v-if="step == 1">
                  <p>Velkommen som bruker på dinbingo.no.</p>
                  <p>Vennligst fyll inn kontaktinformasjon nedenfor.</p>

                  <div role="alert" v-if="errors.length" class="alert alert-danger">
                    <strong>Oisann!</strong>
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                  </div>

                  <base-input
                    alternative
                    class="mb-3"
                    placeholder="Din e-postadresse"
                    addon-left-icon="fa fa-email"
                    v-model="epost"
                  ></base-input>
                  <base-input
                    alternative
                    class="mb-3"
                    placeholder="Ditt mobilnummer"
                    addon-left-icon="ni ni-mobile"
                    v-model="mobil"
                    @keyup.enter="regStep1"
                  ></base-input>
                  <base-checkbox class="mb-3" v-model="acceptedTerms">
                    Ved å registrere deg på dinbingo.no, godtar du våre
                    <a href="#" @click="showTerms = true">kjøps- og bruksvilkår.</a>
                  </base-checkbox>
                  <div class="text-center">
                    <base-button type="success" class="my-4" @click="regStep1">Neste</base-button>
                  </div>
                </div>
                <!-- Step 2 -->
                <div class="col-12" v-if="step == 2">
                  <p>Du vil nå motta en sms på telefonnummer {{ mobil }}</p>
                  <p>Tast inn koden som står i meldingen for å verifisere ditt mobilnummer.</p>

                  <div role="alert" v-if="errors.length" class="alert alert-danger">
                    <strong>Oisann!</strong>
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                  </div>

                  <base-input
                    alternative
                    class="mb-3"
                    placeholder="Kode fra sms"
                    addon-left-icon="fa fa-key"
                    v-model="kode"
                    @keyup.enter="regStep2"
                  ></base-input>
                  <div class="text-center">
                    <base-button type="success" class="my-4" @click="regStep2">Verifiser</base-button>
                  </div>
                </div>
                <!-- Step 3 -->
                <div class="col-12" v-if="step == 3">
                  <p>Mobilnummeret ditt er nå verifisert.</p>
                  <p>Du vil også motta en mail som må verifiseres, men dette kan du gjøre når det passer deg.</p>
                  <h5>Velg ditt passord</h5>
                  <p>Nå skal du velge ditt passord. Finn på noe du husker, men som ikke andre kan gjette.</p>

                  <div role="alert" v-if="errors.length" class="alert alert-danger">
                    <strong>Oisann!</strong>
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                  </div>
                  <p
                    :class="passord.length < 7 ? 'text-warning' : 'text-success'"
                  >{{ 7 > passord.length ? 'Passordet må bestå av minst 7 tegn.' : 'Flott passord!' }}</p>
                  <password
                    placeholder="Ønsket passord"
                    :toggle="true"
                    :defaultClass="'password_form_white'"
                    autocomplete="none"
                    v-model="passord"
                    @keyup.enter="regStep3"
                  ></password>

                  <div class="text-center">
                    <base-button
                      type="success"
                      class="my-4"
                      @click="regStep3"
                      :disabled="passord.length < 7"
                    >Lag konto</base-button>
                  </div>
                </div>
                <!-- Step 4 -->
                <div class="col-12" v-if="step == 4">
                  <p>Du er nå registrert som bruker av din bingo.</p>
                  <p></p>
                  <p>Viderefører deg til min side...</p>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal bankid -->
    <div v-show="showTerms">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content modal-xl-h">
                <div class="modal-header">
                  <h5 class="modal-title">Kjøps- og bruksvilkår.</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showTerms = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="overflow: auto">
                  <!-- Vilkår -->
                  <h6>Produktinformasjon</h6>
                  <p>Dinbingo.no er en tjeneste som gir deg mulighet til å betale for og delta i bingospill uten å være fysisk tilstede i et bingolokale. Ved registrering som kunde på dinbngo.no blir du tildelt en spillerkonto. Formålet med spillerkontoen er at denne skal brukes til betaling for pengespill.</p>
                  <p>For å kunne bruke dinbingo.no må du også være en registrert bruker i din Bingoavdeling. Kontoen på dinbingo.no er knyttet til de Bingoavdelingene du har Vikinglaug medlemskap. Du må ha en konto for hver Bingoavdeling du ønsker å spille i. Du kan ha en konto på dinbingo.no, og en konto per Bingoavdeling. Gjennomføring av selve spillet, utbetaling av premier mv reguleres av Leverandørens brukerbetingelser for spill.</p>
                  <p>Du kan gjøre innskudd på kontoen gjennom overføring av penger fra ditt debetkort. Det er ikke tillatt å spille på kreditt, så overføringer kan ikke skje fra kredittkort. Du kan også sette inn penger på spillerkontoen ved oppmøte hos Leverandøren. Du kan bruke pengene på kontoen til å betale for spill samt eventuelle andre varer eller tjenester som Leverandøren tilbyr mot betaling fra kontoen. Du kan også få utbetalt kontanter fra din spillerkonto ved personlig oppmøte hos Leverandøren. Innskudd/uttak av penger som ikke er i tråd med spillerkontoens hensikt kan få konsekvenser for kundeforholdet.</p>
                  <p>Innestående på spillerkonto tjener ikke opp renter.</p>
                  <p>Du kan få en kontoutskrift fra din lokale Bingoavdeling.</p>
                  <p>Du må selv kontrollere bevegelser på spillerkontoen og melde fra til din lokale Bingoavdeling ved feil.</p>
                  <p>Dersom det er foretatt en feilaktig belastning eller godskriving av din spillerkonto, har den lokale Bingoavdelingen rett og plikt til å rette feilen i spillerkontoen ved korrigering. Dersom din spillerkonto blir feilaktig godskrevet og du disponerer over disse feilaktig godskrevne midlene, plikter du å betale tilbake til den lokale spillehallen det disponerte beløp som det ikke er dekning for på kontoen. Dette gjelder selv om du handlet i god tro.</p>
                  <h6>Priser</h6>
                  <p>Alle priser for spill er i norske kroner. Totalkostnaden for kjøpet vil fremkomme før bestillingen og inkludere alle kostnader forbundet med kjøpet som avgifter og gebyrer.</p>
                  <h6>Personopplysninger</h6>
                  <p>Vi vil i forbindelse med kundeforholdet behandle dine personopplysninger i den grad det er nødvendig for å gjennomføre tjenestene, oppfylle krav stilt til Leverandøren i medhold av lov, eller der vi har annet hjemmelsgrunnlag. Dette er personopplysninger Leverandøren kan behandle uten ditt samtykke. Der det ikke foreligger annet hjemmelsgrunnlag, kan Leverandøren be om ditt samtykke til behandling av personopplysninger. Eksempelvis kan dette gjelde samtykker til å motta markedsføring eller personlig tilpasset innhold. Slike samtykker er frivillig å gi, og du kan når som helst trekke disse samtykkene tilbake.</p>
                  <p>Leverandøren behandler opplysninger om navn, adresse, telefonnummer, spillernummer, e-postadresse, og IP-adresse. Leverandøren er behandlingsansvarlig for lagring og bruk av dine personopplysninger.</p>
                  <p>Leverandøren behandler persondata ifølge Lov om Personopplysninger. Opplysninger som kan knyttes til deg som person vil aldri bli gjort tilgjengelig for andre virksomheter eller bli koblet med andre eksterne register, , med unntak av når offentlige myndigheter krever dette.</p>
                  <p>Som et ledd i arbeidet med å forebygge negative konsekvenser av pengespill og økonomisk kriminalitet, overvåker Leverandøren spill, transaksjoner og bruk av identifiseringsmetoder. Leverandøren har interne retningslinjer for når tiltak iverksettes. Slike tiltak inkluderer blant annet rapportering til offentlig myndighet ved mistanke om kriminell aktivitet. Leverandøren er underlagt plikter i henhold til hvitvaskingsloven. Dette innebærer at Leverandøren har rett og plikt til å behandle personopplysninger (også sensitive) for det formål å forhindre og avdekke hvitvasking og terrorfinansiering.</p>
                  <p>Leverandøren kan bruke underleverandører for behandling av dine personopplysninger. Slik behandling er regulert i egne databehandleravtaler med under Leverandøren.</p>
                  <p>Har du spørsmål om Leverandørens behandling og lagring av dine personopplysninger, ønsker innsyn, korrigering, sletting eller om du ønsker å henvende deg til Leverandørens personvernombud, finner du mer informasjon på www.dinbingo.no/personvern. Hvis du ikke er fornøyd med hvordan Leverandøren besvarer din henvendelse, kan du klage til offentlige tilsynsmyndigheter.</p>
                  <h6>Kortinformasjon</h6>
                  <p>Når du handler hos din Bingoavdeling blir betalingen behandlet av Nets, som er en sikker elektronisk betalingsløsning. All kortinformasjon blir oppbevart i henhold til kortselskapenes regelverk.</p>
                  <h6>ANSVAR</h6>5.1 Generelt
                  <br />
                  <p>Kundeforholdet er personlig, og dine identifiseringsmetoder skal kun anvendes av deg. Det er ikke tillatt å utgi seg for å være noen andre, eller bevisst å oppgi uriktige opplysninger ved registrering, identifisering eller spill. Utlån av egne identifiseringsmetoder, spillerkort eller bruk av andre kunders identifiseringsmetoder/spillerkort er ikke tillatt. All urettmessig bruk vil kunne medføre reaksjoner fra Leverandøren.</p>Eksempler på reaksjoner fra Leverandøren:
                  <br />• Varig eller midlertidig opphør eller sperring av kontoen.
                  <br />• Varig eller midlertidig opphør eller sperring av kundeforholdet til Leverandøren for øvrig
                  <br />• Varig eller midlertidig suspendering av tjenester.
                  <br />• Utestengelse fra Leverandørens lokaler som følge av upassende oppførsel mot Leverandørens ansatte eller øvrige kunder.
                  <br />• Anmeldelse eller varsling til offentlige myndigheter.
                  <p>Dersom det er fare for økonomisk tap, vil vi kunne iverksette reaksjoner uten forutgående varsel.</p>
                  <p>Hvis Leverandøren mistenker hvitvasking, har Leverandøren plikt til å rapportere dette til Økokrim/andre offentlige myndigheter. Hvis Leverandøren ikke kan gjennomføre de kontrolltiltak som følger av hvitvaskingsloven, kan ikke et kundeforhold etableres og/eller transaksjoner gjennomføres. Der det er etablert kundeforhold og slike løpende kontrolltiltak som kreves i henhold til hvitvaskingsloven ikke kan utføres, plikter Leverandøren å avslutte kundeforholdet.</p>
                  <p>Dersom du mener at en transaksjon på din spillerkonto er feil, kan du fremme en klagesak. Du har også anledning til å klage på en utestengelse. Slik klage fremmes til Leverandøren på adresse som nevnt over. Leverandøren har plikt til å undersøke forholdet, behandle klagen og gi deg svar innen rimelig tid.</p>
                  <p>5.2 Ansvarsbegrensning</p>
                  <p>Enhver bruk av identifiseringsmetode som belaster din spillerkonto, er ditt eget ansvar, med mindre bruken finner sted etter at Leverandøren har mottatt varsel om at identifiseringsmetoden skal sperres og sperringen er bekreftet. Dette ansvaret omfatter også tilfeller der identifiseringsmetodene er mistet eller stjålet, selv om du ikke kan bebreides for dette.</p>
                  <p>Leverandøren kan ikke under noen omstendighet holdes ansvarlig for tap som følge av at en identifiseringsmetode ikke kan benyttes, enten dette skyldes feil med identifiseringsmetoden, at den er feilaktig sperret, at systemene til Leverandøren ikke er operative, eller andre forhold.</p>
                  <p>Leverandøren er heller ikke ansvarlig for de tap som du eventuelt påføres ved din bruk av identifiseringsmetoden som er i strid med bestemmelsene i denne avtalen, eller som har oppstått ved mislighold.</p>
                  <p>Leverandørens ansvar er under enhver omstendighet begrenset til selve betalingstransaksjonene. Leverandørens ansvar er begrenset til det direkte tapet, likevel begrenset oppad til NOK 5 000 per transaksjon og NOK 10 000 per kunde per år.</p>

                  <h6>Varighet</h6>
                  <p>Avtalen er løpende og kan når som helst sies opp av begge parter. Ved oppsigelse av avtalen utbetales innskudd ved personlig oppmøte hos Leverandøren.</p>
                  <!-- Vilkår end -->
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showTerms = false">Lukk</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import axios from "../axios-auth";
import funk from "../plugins/functions";
import Password from "vue-password-strength-meter";
import { setTimeout } from "timers";

export default {
  data() {
    return {
      showIframe: true,
      bankIDCanceled: false,
      showLoading: false,
      passord: "",
      epost: "",
      mobil: "",
      acceptedTerms: false,
      regUser: {
        name: ""
      },
      errors: [],
      step: 0,
      kode: "",
      showTerms: false
    };
  },
  methods: {
    bankIdResult: function(err, data) {
      if (err) {
        console.log(err);
        this.bankIDCanceled = true;
        this.showIframe = false;
        // if user is logged in, redirect to mypage
        if (this.$store.getters.isAuthenticated) {
          this.$router.push("/mypage");
        }


      } else {
        return axios
          .post("/bankid/registerBankID", {
            code: data.code
          })
          .then(res => {
            if(res.data.exsistingUser) {

              this.$store.commit('noBankID', false)
              localStorage.setItem('noBankID', false)
              if(this.$store.state.updateAML) {
                //this.$store.state.updateAML = true;
                //localStorage.setItem('updateAML', true)
                this.$router.push("/user/details");
              }else{
                //this.$store.state.updateAML = false;
                //localStorage.setItem('updateAML', false)
                this.$router.push("/mypage");
              }


            }

            this.regUser = res.data;
            this.showIframe = false;
            if (this.regUser.result == "success") {
              this.step = 1;
            }

          })
          .catch(error => {
            console.log(error);
          });
      }

      if (err) {
        return;
      }
    },
    regStep1: function() {
      this.errors = [];
      if (!this.acceptedTerms) {
        this.errors.push("Du må akseptere kjøps- og bruksvilkår.");
      }

      this.epost = this.epost.trim();
      this.mobil = this.mobil.trim();

      if (!funk.validateEmail(this.epost)) {
        this.errors.push("Det er en feil med epostadressen.");
      }
      if (isNaN(this.mobil)) {
        this.errors.push("Mobilnummer kan kun bestå av tall");
      } else if (this.mobil.length != 8) {
        this.errors.push("Mobilnummer må bestå av 8 tall");
      }

      if (this.errors.length) return;

      axios
        .post("/user/signupStep1", {
          storeHash: this.regUser.storeHash,
          epost: this.epost,
          mobil: this.mobil
        })
        .then(res => {

          this.errors = res.data.error;
          if (res.data.result == "OK") this.step = 2;
        })
        .catch(error => {
          console.log(error);
        });
    },
    regStep2: function() {
      this.errors = [];

      if (isNaN(this.kode)) {
        this.errors.push("Koden skal kun inneholde tall");
      }

      if (this.errors.length) return;

      axios
        .post("/user/signupStep2", {
          storeHash: this.regUser.storeHash,
          kode: this.kode,
          mobil: this.mobil
        })
        .then(res => {

          this.errors = res.data.error;
          if (res.data.result == "OK") this.step = 3;
        })
        .catch(error => {
          console.log(error);
        });
    },
    regStep3: function() {
      this.errors = [];

      if (this.passord.length < 7) {
        this.errors.push("Passordet må bestå av minst 7 tegn.");
      }

      if (this.errors.length) return;

      axios
        .post("/user/signupStep3", {
          storeHash: this.regUser.storeHash,
          kode: this.kode,
          mobil: this.mobil,
          epost: this.epost,
          passord: this.passord
        })
        .then(res => {

          this.errors = res.data.error;
          if (res.data.result == "OK") {
            this.step = 3;

            this.$store
              .dispatch("login", {
                username: this.mobil,
                password: this.passord
              })
              .then(
                res => {
                  if (res == 1) {
                    this.step = 4;

                    setTimeout(
                      function() {
                        this.$router.push("/mypage");
                      }.bind(this),
                      5000
                    );
                  }
                  //if(res == 0) { this.showWrongPassword = true }
                  //this.showLoading = false
                  //console.log(res)
                },
                error => {
                  console.log(error);
                }
              );
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    startOver() {
      this.showIframe = true;
      this.bankIDCanceled = false;
      OIDC.doConnect({
        config: {
          login_hint: "",
          scope: "openid profile nnin_altsub"
        },
        callback: this.bankIdResult
      });
    },
    loadBankID() {

      OIDC.doInit({
        oidc_url:
          "https://auth.bankid.no/auth/realms/prod/.well-known/openid-configuration",
        client_id: "dinbingono_707428b1-bankid-prod",
        //redirect_uri: "https://dinbingo.no/register",
        redirect_uri: "https://dinbingo.no/register",
        method: "redirect"
      })
        .then(() => {
          OIDC.doConnect({
            config: {
              login_hint: "",
              scope: "openid profile nnin_altsub"
            },
            callback: this.bankIdResult
          });
        })
        .catch(console.log);
    }
  },
  mounted: function() {

    if(this.$route.query.state) {

      this.bankIdResult(this.$route.query.error,this.$route.query);

    }else{

    this.loadBankID();

    }
  },
  components: {
    Password
  }
};
</script>
<style>
.password_form_white {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px;
  width: 100%;
}
</style>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-dialog {
  z-index: 9999;
  max-width: 550px !important;
  max-height: 100vh !important;
}
.modal-content {
  max-height: 95vh !important;
}

.modal-wrapper {
  z-index: 9998;
  display: table-cell;
  vertical-align: middle;
}
.modal-xl {
  z-index: 9999;
  max-width: 1200px !important;
  height: 98% !important;
}
.modal-xl-h {
  height: 100% !important;
}
.modal-xl-h-90 {
  display: block !important;
  height: 90% !important;
}
</style>
