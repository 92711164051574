<template >
  <footer class="footer">
    <div class="container">
      <hr />
      <div class="row align-items-center justify-content-md-between">
        <div class="col-sm-12 mt-sm-12 mt-lg-12">
          <ul class="nav nav-footer justify-content-between align-items-baseline d-flex flex-wrap">
            <li class="nav-item pb-2 pr-4">
              <a href="https://lotteritilsynet.no" target="_blank" title="Lotteritilsynet">
                <img class="card-img" src="/img/brand/Lottstift_Merke_tekst_farge.png" lazy="loaded" />
              </a>
            </li>
            <li class="nav-item pb-2">
              <a href="https://hjelpelinjen.no" target="_blank" title="Hjelpelinjen">
                <img class="card-img" src="/img/brand/Hjelpelinjen.png" lazy="loaded" />
              </a>
            </li>
            <!-- Wrap the last item in a div to push it to the right -->
            <div class="ml-auto">
              <li class="nav-item pb-2">
                <img class="card-img" src="/img/brand/18aar.png" lazy="loaded" />
              </li>
            </div>
          </ul>
        </div>

        <div class="col-sm-12 mt-2">
          <div class="copyright">
            &copy; {{ year }}
            <a href="https://www.aspelund.no" target="_blank" rel="noopener"
            >K. Aspelund AS</a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
