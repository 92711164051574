<template>
  <div class="container">
    <div v-if="currentStep === 1">
      <h2>Personlig informasjon</h2>
      <form @submit.prevent="nextStep">
        <!-- Fornavn og Etternavn -->
        <div class="row">
        <div class="form-group col-6">
          <label for="city">Fornavn</label>
          <input type="text" id="city" v-model="formData.firstName" class="form-control" readonly />
        </div>
        <div class="form-group col-6">
          <label for="city">Etternavn</label>
          <input type="text" id="city" v-model="formData.lastName" class="form-control" readonly />
        </div>
        </div>

        <!-- Adresse -->
        <div class="form-group">
          <label for="address">Adresse</label>
          <input type="text" id="address" v-model="formData.address" class="form-control" required />
        </div>

        <!-- Postnr og Poststed -->
        <div class="row">
        <div class="form-group col-4">
          <label for="postNr">Postnummer</label>
          <input type="text" id="postNr" v-model="formData.postNr" @blur="fetchCity" class="form-control" required />
        </div>
        <div class="form-group col-8">
          <label for="city">Poststed</label>
          <input type="text" id="city" v-model="city" class="form-control" readonly />
        </div>
        </div>

        <!-- Bostedsland -->
        <div class="form-group">
          <label for="nationality">Bostedsland</label>
          <select id="nationality" v-model="formData.nationality" class="form-control">
            <option v-for="country in citizenshipOptions" :key="country" :value="country">
              {{ country }}
            </option>
          </select>
        </div>

        <!-- Kampanjer Checkbox -->
        <div class="form-check">
          <base-checkbox type="checkbox" id="campaigns" v-model="formData.noSMS" class="form-check-input mb-3">Reserver mot kampanjer og info på SMS</base-checkbox>
        </div>

        <!-- PEP -->
        <div class="form-group">
          <label>Er du en politisk eksponert person (PEP)?</label><br>
          <p><small>Du regnes som en PEP (Politisk Eksponert Person) dersom du, eller noen i din nærmeste familie, har eller tidligere har hatt et høytstående verv eller stilling, enten i Norge eller i utlandet.
          <br>Usikker? Du kan se listen <a @click="pepInfoVisible = !pepInfoVisible" style="cursor: pointer;">her.</a></small></p>
          <div v-if="pepInfoVisible" class="mt-3">
            <small>
            <ul>
              <li>Statsleder, regjeringssjef, minister eller assisterende minister</li>
              <li>Medlem av nasjonalforsamling</li>
              <li>Medlem av styret i et politisk parti</li>
              <li>Dommer i høyere rettsinstans som fatter endelige beslutninger som ikke kan ankes</li>
              <li>Styremedlem i riksrevisjon, revisjonsdomstol eller sentralbank</li>
              <li>Ambassadør, chargé d'affaires eller offiser i militæret på høyere nivå</li>
              <li>Leder, nestleder eller kontrollorgan i statlige foretak</li>
              <li>Direktør, styremedlem eller annen ledende stilling i internasjonale organisasjoner</li>
            </ul>
            </small>
          </div>
          <div class="form-check">
            <base-radio
                id="pepYes"
                name="Ja"
                v-model="_isPepLocal"
                class="form-check-input"
            >
              Jeg er PEP
            </base-radio>
          </div>
          <div class="form-check">
            <base-radio
                id="pepNo"
                name="Nei"
                v-model="_isPepLocal"
                class="form-check-input"
            >
              Jeg er ikke PEP
            </base-radio>
          </div>
        </div>

        <!-- Dynamisk PEP-info -->
        <div v-if="formData.isPep === true">
          <div class="form-group">
            <label for="pepText">Hvorfor er du PEP?</label>
            <input type="text" id="pepText" v-model="formData.pepText" class="form-control" required />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <button v-if="currentStep > 1" @click.prevent="prevStep" class="btn btn-secondary">Tilbake</button>
          <button type="submit" class="btn btn-primary ml-auto">Gå videre</button>
        </div>
      </form>
    </div>

    <div v-else-if="currentStep === 2">
      <h2>PEP Detaljer</h2>
      <form @submit.prevent="nextStep">
        <!-- Flere PEP Spørsmål -->
        <div class="form-group">
          <label for="pepName">Navn på PEP</label>
          <input type="text" id="pepName" v-model="formData.pepName" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="pepRelation">Din relasjon til PEP</label>
          <input type="text" id="pepRelation" v-model="formData.pepRelation" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="pepFDate">Fødselsdato</label>
          <input type="date" id="pepFDate" v-model="formData.pepFDate" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="pepSex">Kjønn</label>
          <select id="pepSex" v-model="formData.pepSex" class="form-control" required>
            <option value="M">Mann</option>
            <option value="F">Kvinne</option>
          </select>
        </div>

        <div class="d-flex justify-content-between">
          <button v-if="currentStep > 1" @click.prevent="prevStep" class="btn btn-secondary">Tilbake</button>
          <button type="submit" class="btn btn-primary ml-auto">Gå videre</button>
        </div>
      </form>
    </div>

    <div v-else-if="currentStep === 3">
      <h2>Hvilke midler skal du spille for?</h2>
      <form @submit.prevent="nextStep">
        <div class="form-check">
          <base-checkbox type="checkbox" id="salary" v-model="formData.fundedBySalary" class="form-check-input">Lønn</base-checkbox>
        </div>
        <div class="form-check">
          <base-checkbox type="checkbox" id="realEstate" v-model="formData.fundedByRealEstate" class="form-check-input mb-2">Salg/utleie av eiendom</base-checkbox>
        </div>
        <div class="form-check">
          <base-checkbox type="checkbox" id="stocks" v-model="formData.fundedByStocks" class="form-check-input mb-2">Aksjer</base-checkbox>
        </div>
        <div class="form-check">
          <base-checkbox type="checkbox" id="socialSecurity" v-model="formData.fundedBySocialSecurity" class="form-check-input mb-2">Trygd, pensjon eller andre støtteordninger</base-checkbox>
        </div>
        <div class="form-check">
          <base-checkbox type="checkbox" id="gifts" v-model="formData.fundedByGifts" class="form-check-input mb-2">Gaver, arv eller lignende</base-checkbox>
        </div>
        <div class="form-check">
          <base-checkbox type="checkbox" id="other" v-model="formData.fundedByOther" class="form-check-input mb-2">Annet</base-checkbox>
        </div>
        <div v-if="formData.fundedByOther" class="form-group">
          <label for="fundedByOtherText">Beskrivelse</label>
          <textarea id="fundedByOtherText" v-model="formData.fundedByOtherText" class="form-control"></textarea>
        </div>

        <div class="d-flex justify-content-between mt-4">
          <button v-if="currentStep > 1" @click.prevent="prevStep" class="btn btn-secondary">Tilbake</button>
          <button type="submit" class="btn btn-primary ml-auto">Gå videre</button>
        </div>
      </form>
    </div>
    <div v-else-if="currentStep === 4">
      <!-- Oppsummering -->


      <h2>Oppsummering</h2>
      <div class="summary">
        <p><strong>Kundeinformasjon:</strong></p>
        <div>{{ formData.firstName }} {{ formData.lastName }}</div>
        <div>{{ formData.address }}</div>
        <div>{{ formData.postNr }} {{ city }}</div>
        <p class="mt-3"><strong>Bostedsland:</strong> {{ formData.nationality }}</p>
        <p><strong>PEP:</strong> {{ formData.isPep ? 'Ja' : 'Nei' }}</p>

        <div v-if="formData.isPep">
          <p><strong>Hvorfor PEP:</strong> {{ formData.pepText }}</p>
          <p><strong>Navn på PEP:</strong> {{ formData.pepName }}</p>
          <p><strong>Relasjon til PEP:</strong> {{ formData.pepRelation }}</p>
          <p><strong>Fødselsdato PEP:</strong> {{ formData.pepFDate }}</p>
          <p><strong>Kjønn PEP:</strong>
            <span v-if="formData.pepSex === 'M'">Mann</span>
            <span v-else-if="formData.pepSex === 'F'">Kvinne</span>
          </p>
        </div>

        <p v-if="this.formData.isPep === true || this.countries.find(country => country.name === this.formData.nationality && country.risk === true)"><strong>Midler:</strong> {{ formattedFundingSources }}</p>
      </div>
      <div
          v-if="errorMessage.visible"
          class="alert alert-warning"
          role="alert"
      >
        <strong>Oida!</strong>
        {{ errorMessage.text }}
      </div>

      <div class="d-flex justify-content-between mt-4">
        <button @click.prevent="prevStep" class="btn btn-secondary">Tilbake</button>
        <button @click.prevent="submitForm" class="btn btn-success ml-auto" :disabled="sendingData">Bekreft</button>
      </div>
    </div>
    <div v-else-if="currentStep === 99">
      <!-- Oppsummering -->
      <h2>Fullført</h2>
      <div class="summary">
          Takk for din registrering av brukerinformasjon.


      </div>
      <div class="d-flex justify-content-between mt-4">
        <button @click.prevent="goToMyPage" class="btn btn-success ml-auto">Gå til min side</button>
      </div>
    </div>
  </div>
</template>

<script>
import {getCountries, getPostPlace, myUserDetails, setUserInfo} from "@/wapFunctions";

export default {
  name: "AMLFormWizard",
  data() {
    return {
      sendingData: false,
      errorMessage: {
        visible: false,
        text: ""
      },
      currentStep: 1,
      pepInfoVisible: false,
      countries: [],
      formData: {
        firstName: "",
        lastName: "",
        address: "",
        postNr: "",
        nationality: "",
        isPep: false,
        pepText: "",
        pepRelation: "",
        pepName: "",
        pepFDate: new Date().toISOString().split('T')[0],
        pepSex: "",
        noSMS: false,
        fundedBySalary: false,
        fundedByRealEstate: false,
        fundedByStocks: false,
        fundedBySocialSecurity: false,
        fundedByGifts: false,
        fundedByOther: false,
        fundedByOtherText: "",
      },
      citizenshipOptions: [], // Hentes fra API
      city: ''
    };

  },
  computed: {
    formattedFundingSources() {
      const sources = [];
      if (this.formData.fundedBySalary) sources.push("Lønn");
      if (this.formData.fundedByRealEstate) sources.push("Eiendom");
      if (this.formData.fundedByStocks) sources.push("Aksjer");
      if (this.formData.fundedBySocialSecurity) sources.push("Trygd");
      if (this.formData.fundedByGifts) sources.push("Gaver");
      if (this.formData.fundedByOther) sources.push(this.formData.fundedByOtherText);
      return sources.length > 0 ? sources.join(", ") : "Ingen";
    },
    _isPepLocal: {
      get() {
        return this.formData.isPep ? 'Ja' : 'Nei';
      },
      set(value) {
        this.formData.isPep = value === 'Ja';
      }
    }
  },
  watch: {
    'formData.postNr': 'fetchCity',
    'formData.nationality': function(newVal) {
      if (newVal === "" && this.citizenshipOptions.length > 0) {
        this.formData.nationality = this.citizenshipOptions[0];
      }
    }

  },
  methods: {
    async fetchCity() {
      try {
        const response = await getPostPlace(this.formData.postNr);
        this.city = response.poststed.toUpperCase();
      } catch (error) {
        console.error("Error fetching city:", error);
        this.city = "";
      }
    },
    async fetchCitizenshipOptions() {

      // using wapFunctions to get citizenship options
      getCountries().then((response) => {
        this.countries = response.countries;
        this.citizenshipOptions = this.countries.map(country => country.name);

        if (this.formData.nationality === "" && this.citizenshipOptions.length > 0) {
          this.formData.nationality = this.citizenshipOptions[0];
        }



      });

    },
    goToMyPage() {
      this.$router.push("/mypage");
    },
    nextStep() {

      if(this.currentStep === 1 && this.formData.isPep === false) {
        this.currentStep++;

        if(this.countries.find(country => country.name === this.formData.nationality && country.risk === false)) {
          this.currentStep++;
        }

      }

      this.currentStep++;
    },
    prevStep() {

      if(this.currentStep === 3 && this.formData.isPep === false) {
        this.currentStep--;
      }
      if(this.currentStep === 4 && this.formData.isPep === false && this.countries.find(country => country.name === this.formData.nationality && country.risk === false)) {
        this.currentStep--;
        this.currentStep--;
      }


      this.currentStep--;
    },
    submitForm() {

      if(this.sendingData) {
        return;
      }

      this.sendingData = true;
      // use setUserInfo to save the form data

      setUserInfo(this.$store.state.idToken, this.formData).then((response) => {

        // if response is successful and contains this data: {"setUserInfo":{"status":0,"message":""}} and status is 0 then show success message

        if (response && response.setUserInfo && response.setUserInfo.status === 0) {
          this.$store.commit('updateAML', false);
          this.$store.commit('updateForceAML', false);
          localStorage.setItem('updateAML', 'false');
          localStorage.setItem('forceAML', 'false');
          this.currentStep = 99;
        }else{
          this.errorMessage.visible = true;
          this.errorMessage.text = response.setUserInfo.message;

          setTimeout(() => {
            this.errorMessage.visible = false;
          }, 5000);

          this.sendingData = false;
        }


      });


      console.log("Form submitted", this.formData);
    },
    getMyUserDetails() {

      myUserDetails(this.$store.state.idToken).then((response) => {
        console.log("User details", response);

        if(response.userAMLData) {
          this.formData = { ...this.formData, ...response.userAMLData };
        }

        this.formData.firstName = response.userData.userFirstname;
        this.formData.lastName = response.userData.userLastname;

        if (this.formData.pepFDate === null || this.formData.pepFDate === "") {
          this.formData.pepFDate = new Date().toISOString().split('T')[0];
        }

      });

    },
  },
  mounted() {
    this.fetchCitizenshipOptions();
    this.getMyUserDetails();
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
  max-width: 600px;
}
</style>
