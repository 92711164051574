<template>
  <div class="card" v-bind:class="{ 'border-danger': hall.DaysBlocked > 0 }">
    <div class="card-body">
      <span
        class="badge badge-placement"
        v-bind:class="{
          'badge-success': hall.balance_str != 'Kortfeil - kontakt betjening',
          'badge-danger': hall.balance_str == 'Kortfeil - kontakt betjening',
        }"
        >{{
          hall.balance_str == "0" ? "Henter saldo..." : hall.balance_str
        }}</span
      >
      <div class="row" v-if="messages && messages.length > 0">
        <div class="container mx-0 px-0">
          <div v-bind:key="message.messageText" v-for="message in messages">
            <div v-if="message.messageHallID == hall.BingoID">
              <div
                v-if="message.messageType == 'error'"
                class="alert alert-danger text-white mb-2 mt-2"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else-if="message.messageType == 'warning'"
                class="alert alert-warning text-white mb-2 mt-2"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else-if="message.messageType == 'info'"
                class="alert alert-info text-white mb-2 mt-2"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
              <div
                v-else
                class="alert alert-default text-white mb-2 mt-2"
                role="alert"
              >
                <strong>{{ message.messageHeader }}</strong>
                {{ message.messageText }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-2">
          <img
            v-if="hall.BingoLogo"
            :src="'/img/brand/logo/' + hall.BingoLogo"
          />
        </div>
        <div class="col-12 col-lg-5 pl-0">
          <div class="col-12 font-weight-bold">
            {{ hall.BingoName }}
          </div>
          <div class="col-12">
            {{ hall.BingoAddress }}
          </div>
        </div>
        <div class="col-12 col-lg-5 mx-0 px-0">
          <button
            v-if="!this.$store.state.noBankID && hall.WebAppAktivert == 1"
            type="button"
            @click="OpenBingoPage(hall)"
            class="btn m-2 btn-success btn-sm float-right"
          >
            Bingoside
          </button>
          <button
            v-if="!this.$store.state.noBankID && hall.BingoPayOut == 1"
            type="button"
            @click="withdrawCard(hall)"
            class="btn m-2 btn-yellow btn-sm float-right"
          >
            Uttak
          </button>
          <button
            v-if="
              hall.nets_clientid &&
              hall.balance_str &&
              hall.balance_str.includes('kr') &&
              hall.DaysBlocked < 1
            "
            type="button"
            @click="fillCard(hall)"
            class="btn m-2 btn-primary btn-sm float-right"
          >
            Innskudd
          </button>
          <button
            v-if="
              hall.balance_str != 'Kortfeil - kontakt betjening' &&
              hall.showTransactions == 1
            "
            type="button"
            @click="openTransactionsBox(hall)"
            class="btn m-2 btn-yellow btn-sm float-right"
          >
            Transaksjoner
          </button>
        </div>
        <div class="col-12 mx-0 px-0" v-if="hall.DaysBlocked < 1">
          <img
            v-if="hall.H1Active == '1'"
            src="img/brand/bingo_button.png"
            @click="playMainGame(hall)"
            class="m-2 float-right"
            style="cursor: pointer"
          />
          <img
            v-if="hall.H1Active == '0'"
            src="img/brand/h1_closed.png"
            class="m-2 float-right"
          />

          <img
            v-if="hall.H2Active == '1'"
            src="img/brand/h2_button.png"
            @click="playMainGame2(hall)"
            class="m-2 float-right"
            style="cursor: pointer"
          />
          <img
            v-if="hall.H2Active == '0'"
            src="img/brand/h2_closed.png"
            class="m-2 float-right"
          />
          <img
            v-if="hall.H3Active == '1'"
            src="img/brand/lynbingo.png"
            @click="playMainGame3(hall)"
            class="m-2 float-right"
            style="cursor: pointer"
          />
          <img
            v-if="hall.H3Active == '0'"
            src="img/brand/lynbingo_closed.png"
            class="m-2 float-right"
          />
          <img
            v-if="hall.ortiz == 'true'"
            src="img/brand/goalmania_button.png"
            @click="playRemoteMainGame(hall, 501, 'Goal Mania')"
            class="m-2 float-right"
            style="cursor: pointer"
          />
          <img
            v-if="false == true"
            src="img/brand/beachbingo_button.png"
            @click="playRemoteMainGame(hall, 503, 'Goal Mania')"
            class="m-2 float-right"
            style="cursor: pointer"
          />
        </div>
        <div
          class="col-12 mx-0 px-0 mt-2 float-right text-right"
          style="float: right"
          v-if="
            hall.H3Active == '0' &&
            hall.H2Active == '0' &&
            hall.H1Active == '0' &&
            messages &&
            messages.length == 0
          "
        >
          Spillene er stengt, og vil åpnes i bingosalongens åpningstid.
        </div>
        <div
          class="col-12 mx-0 px-0 mt-2 float-right text-right"
          style="float: right"
          v-else-if="
            (hall.H3Active == '0' ||
              hall.H2Active == '0' ||
              hall.H1Active == '0') &&
            messages &&
            messages.length == 0
          "
        >
          Et eller flere spill er stengt for dagen, spillene er åpne i
          bingosalongens åpningstid
        </div>
        <div
          class="col-12 mx-0 px-0 mt-2 text-danger"
          v-if="hall.DaysBlocked > 0"
        >
          Du har stengt seg ute i fra denne bingosalongen. Utestengelsen
          oppheves om {{ hall.DaysBlocked }} dager.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "../axios-auth";

export default {
  name: "my-bingo-hall",
  props: {
    hall: {
      type: Object,
      default: null,
      description: "Bingo Data",
      balance: null,
      balance_str: null,
    },
    messages: {
      type: Array,
      default: null,
      description: "Messages for bingo",
    },
  },
  methods: {
    OpenBingoPage(hall) {
      // navigate to bingo page route mybingo/hall.BingoID and change querystring to /mybingo/hall.BingoID
      this.$router.push({ name: "mybingo", params: { id: hall.BingoID } });
    },
    fillCard(hall) {
      this.$emit("openCard", hall);
    },
    withdrawCard(hall) {
      this.$emit("withdrawCard", hall);
    },
    playMainGame(hall) {
      this.$emit("playMainGame", hall);
    },
    playMainGame2(hall) {
      this.$emit("playMainGame2", hall);
    },
    playMainGame3(hall) {
      this.$emit("playMainGame3", hall);
    },
    playRemoteMainGame(hall, gameid, gameName = "") {
      this.$emit("playRemoteMainGame", hall, gameid, gameName);
    },
    openTransactionsBox(hall) {
      this.$emit("openTransactionsBox", hall);
    },
    getHallBalance(hall) {
      axios
        .post(
          "/banking/balance",
          { bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          if (isNaN(res.data.balance)) {
            this.balance_str = "Kortfeil - kontakt betjening";
            this.balance = 0;
          } else {
            this.balance_str =
              "kr " + new Intl.NumberFormat().format(res.data.balance);
            this.balance = res.data.balance;
          }

        });
    },
  },
};
</script>

<style scoped>
.badge-placement {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 5px 5px;
  color: black;
  text-transform: initial;
}
</style>