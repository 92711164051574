<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default shape-skew">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-1"
            body-classes="px-lg-2 py-lg-4"
            class="border-0"
          >
            <template>
              <div class="text-center text-muted mb-4 h4">
                <small>Opprett nytt passord</small>
              </div>
              <div
                v-show="showIframe"
                id="auth"
                style="height: fit-content; height: 300px"
              ></div>
              <div class="col-12" v-if="bankIDCanceled">
                <p>Autentiseringen med BankID ble avbrutt eller feilet.</p>
                <p>Skjedde dette ved en feil, vennligst prøv igjen.</p>

                <div class="text-center">
                  <base-button
                    tag="a"
                    @click="startOver"
                    class="mb-3 mb-sm-0 text-white"
                    type="success"
                    icon="fa fa-user-plus"
                  >
                    Prøv på nytt
                  </base-button>
                </div>
              </div>

              <!-- Step 1 -->
              <div class="col-12" v-if="step == 1">
                <p>Tast ditt mobilnummer, for å få tilsendt en engangskode.</p>

                <div
                  role="alert"
                  v-if="errors.length"
                  class="alert alert-danger"
                >
                  <strong>Oisann!</strong>
                  <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>

                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Ditt mobilnummer"
                  addon-left-icon="ni ni-mobile"
                  v-model="mobil"
                  @keyup.enter="regStep1"
                >
                </base-input>
                <div class="text-center">
                  <base-button type="success" class="my-4" @click="regStep1"
                    >Neste</base-button
                  >
                </div>
              </div>
              <!-- Step 2 -->
              <div class="col-12" v-if="step == 2">
                <p>Du vil nå motta en sms på telefonnummer {{ mobil }}</p>
                <p>
                  Tast inn koden som står i meldingen for å verifisere ditt
                  mobilnummer.
                </p>

                <div
                  role="alert"
                  v-if="errors.length"
                  class="alert alert-danger"
                >
                  <strong>Oisann!</strong>
                  <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>

                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Kode fra sms"
                  addon-left-icon="fa fa-key"
                  v-model="kode"
                  @keyup.enter="regStep2"
                >
                </base-input>
                <div class="text-center">
                  <base-button type="success" class="my-4" @click="regStep2"
                    >Verifiser</base-button
                  >
                </div>
              </div>
              <!-- Step 3 -->
              <div class="col-12" v-if="step == 3">
                <p>Mobilnummeret ditt er nå verifisert.</p>
                <h5>Velg nytt passord</h5>
                <p>
                  Nå skal du velge nytt passord. Finn på noe du husker, men som
                  ikke andre kan gjette.
                </p>

                <div
                  role="alert"
                  v-if="errors.length"
                  class="alert alert-danger"
                >
                  <strong>Oisann!</strong>
                  <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>
                <p
                  :class="passord.length < 7 ? 'text-warning' : 'text-success'"
                >
                  {{
                    7 > passord.length
                      ? "Passordet må bestå av minst 7 tegn."
                      : "Flott passord!"
                  }}
                </p>
                <password
                  placeholder="Ønsket passord"
                  :toggle="true"
                  :defaultClass="'password_form_white'"
                  autocomplete="none"
                  v-model="passord"
                  @keyup.enter="regStep3"
                ></password>

                <div class="text-center">
                  <base-button
                    type="success"
                    class="my-4"
                    @click="regStep3"
                    :disabled="passord.length < 7"
                    >Oppdater passord</base-button
                  >
                </div>
              </div>
              <!-- Step 4 -->
              <div class="col-12" v-if="step == 4">
                <p>Passordet er nå endret.</p>
                <p></p>
                <p>Viderefører deg til min side...</p>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "../axios-auth";
import Password from "vue-password-strength-meter";
import { setTimeout } from "timers";

export default {
  data() {
    return {
      showIframe: false,
      bankIDCanceled: false,
      showLoading: false,
      passord: "",
      epost: "",
      mobil: "",
      regUser: {
        name: "",
      },
      errors: [],
      step: 1,
      kode: "",
    };
  },
  methods: {
    bankIdResult: function (err, data) {
      if (err) {
        console.log(err);
        this.bankIDCanceled = true;
        this.showIframe = false;
      } else {
        return axios
          .post("/bankid/registerBankID", {
            code: data.code,
          })
          .then((res) => {

            this.regUser = res.data;
            this.showIframe = false;
            if (this.regUser.result == "success") {
              this.step = 1;
            }

          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (err) {
        return;
      }
    },
    regStep1: function () {
      this.errors = [];

      if (isNaN(this.mobil)) {
        this.errors.push("Mobilnummer kan kun bestå av tall");
      } else if (this.mobil.length != 8) {
        this.errors.push("Mobilnummer må bestå av 8 tall");
      }

      if (this.errors.length) return;

      axios
        .post("/user/lostPasswordStep1", {
          mobil: this.mobil,
        })
        .then((res) => {

          this.errors = res.data.error;
          if (res.data.result == "OK") this.step = 2;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regStep2: function () {
      this.errors = [];

      if (isNaN(this.kode)) {
        this.errors.push("Koden skal kun inneholde tall");
      }

      if (this.errors.length) return;

      axios
        .post("/user/lostPasswordStep2", {
          kode: this.kode,
          mobil: this.mobil,
        })
        .then((res) => {

          this.errors = res.data.error;
          if (res.data.result == "OK") this.step = 3;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regStep3: function () {
      this.errors = [];

      if (this.passord.length < 7) {
        this.errors.push("Passordet må bestå av minst 7 tegn.");
      }

      if (this.errors.length) return;

      axios
        .post("/user/lostPasswordStep3", {
          kode: this.kode,
          mobil: this.mobil,
          passord: this.passord,
        })
        .then((res) => {

          this.errors = res.data.error;
          if (res.data.result == "OK") {
            this.step = 3;

            this.$store
              .dispatch("login", {
                username: this.mobil,
                password: this.passord,
              })
              .then(
                (res) => {
                  if (res == 1) {
                    this.step = 4;

                    setTimeout(
                      function () {
                        this.$router.push("/mypage");
                      }.bind(this),
                      5000
                    );
                  }
                  //if(res == 0) { this.showWrongPassword = true }
                  //this.showLoading = false
                  //console.log(res)
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startOver() {
      this.showIframe = true;
      this.bankIDCanceled = false;
      OIDC.doConnect({
        config: {
          login_hint: "",
          scope: "openid profile",
        },
        inlineElementID: "auth",
        callback: this.bankIdResult,
      });
    },
    loadBankID() {
      console.log("Initierer bankid");

      OIDC.doInit({
        oidc_url:
          "https://auth.bankid.no/auth/realms/prod/.well-known/openid-configuration",
        client_id: "kaspelund-bankid-prod",
        redirect_uri: "https://dinbingo.no/bankIdreturn",
        method: "inline",
      })
        .then(() => {
          console.log("Kobler til bankid");
          OIDC.doConnect({
            config: {
              login_hint: "",
              scope: "openid profile",
            },
            inlineElementID: "auth",
            callback: this.bankIdResult,
          });
        })
        .catch(console.log);
    },
  },
  mounted: function () {
    // this.loadBankID();
  },
  components: {
    Password,
  },
};
</script>
<style>
.password_form_white {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px;
  width: 100%;
}
</style>
