<template>
    <div>
        <p>{{resulttext}}</p>
    </div>
</template>
<script>
import axios from '../axios-auth'


export default {
    data() {
        return {
        resulttext: "Venter..."
         
        }

    },
    mounted: function() {
        if(this.$route.query.responseCode == "OK") {
            this.resulttext = "Venter på resultat..."
        
        let verifyUrl = '/payment/verify';
        if(this.$route.query.payment) {
            verifyUrl = '/payment/sale';
        }
        axios
        .post(verifyUrl, { transactionId: this.$route.query.transactionId }, {
            headers: { Authorization: "Bearer " + this.$store.state.idToken }
        })
        .then(res => {
          if(this.$route.query.payment) {
            if(res.status == 200) {
                this.resulttext = "Transaksjon vellykket!"
                window.parent.postMessage('finishedPayment', '*');
            }else{
                this.resulttext = "Transaksjon feilet!"
                window.parent.postMessage('failedPayment', '*');
            }
          }else{
            if(res.status == 200) {
                this.resulttext = "Lagring av kort vellykket!"
                window.parent.postMessage('finished', '*');
            }else{
                this.resulttext = "Lagring av kort feilet!"
            }
          }
          }).catch((err) => {
            if(this.$route.query.payment) {
                    this.resulttext = "Transaksjon feilet!"
                    window.parent.postMessage('failedPayment', '*');
            }else{
                    this.resulttext = "Lagring av kort vellykket!"
                    window.parent.postMessage('failed', '*');
            }
          })



        }else{
            this.resulttext = "Det oppsto en feil med transaksjonen"
        }
  
    }

};
</script>
<style scoped>
div {
    height: 200px;
    width: 400px;

    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
}

</style>