<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--500 p-4" no-body>
        <AMLFormWizard />
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import AMLFormWizard from "@/components/AMLFormWizard.vue";

export default {
  components: {AMLFormWizard},
  data() {
    return {
      loading: false,
      responseText: '',
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style>
.spinner {
  font-size: 22px;
  text-align: center;
}
</style>