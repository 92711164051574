<template>
  <header class="header-global">
    <base-nav ref="BaseNav" class="navbar-main" transparent type effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="/img/brand/white.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{closeMenu}">
        <div class="col-6 collapse-brand">
          <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
            <img src="/img/brand/blue.png" />
          </router-link>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <base-dropdown class="nav-item mb-lg-0 mb-2" menu-classes="dropdown-menu-xl showdropdown">
          <a
            slot="title"
            href="#"
            class="nav-link d-none d-lg-inline"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Informasjon</span>
          </a>
          <div class="dropdown-menu-inner" @click="closeMenu">
            <router-link to="/bingohalls" class="media d-flex align-items-center">
              <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                <i class="ni ni-square-pin"></i>
              </div>
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">Finn din bingo</h6>
                <p
                  class="description d-none d-md-inline-block mb-0"
                >Finn den nærmeste bingosalongen fra din posisjon eller søk med postnummer.</p>
              </div>
            </router-link>
            <router-link to="/howtoplay" class="media d-flex align-items-center">
              <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                <i class="fa fa-info-circle"></i>
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-success mb-md-1">Hvordan spille bingo</h5>
                <p
                  class="description d-none d-md-inline-block mb-0"
                >Les hvordan du går frem hvis bingo er nytt for deg.</p>
              </div>
            </router-link>
             <div class="media d-flex align-items-center livechat_button">
              <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                <i class="fa fa-comment"></i>
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-success d-none d-md-inline-block mb-md-1">Har du spørsmål?</h5>
                <div
                 data-id="c99d1dec58" class="livechat_button description d-md-inline-block mb-0"
                ><a href="https://www.livechatinc.com/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_11837550">Chat med oss.</a></div>
              </div>
            </div>
          </div>
        </base-dropdown>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item d-lg-block ml-lg-4" @click="closeMenu">
          <router-link to="/login" rel="noopener" v-show="!auth" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fa fa-sign-in mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Logg inn</span>
          </router-link>
          <router-link to="/mypage" rel="noopener" v-show="auth" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fa fa-user mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Min side</span>
          </router-link>
          <a
            @click="onLogout"
            rel="noopener"
            v-show="auth"
            class="btn btn-warning btn-icon"
            data-toggle="tooltip"
            title="Logg ut"
          >
            <span class="btn-inner--icon text-white">
              <i class="fa fa-lock mr-lg-0 mr-2"></i>
            </span>
            <span class="nav-link-inner--text d-lg-none text-white">Logg ut</span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    closeMenu() {
      this.$refs.BaseNav.closeMenu();
    }
  }
};
</script>
<style>
.showdropdown {
  display: block !important;
}
</style>
