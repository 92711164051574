<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--500" no-body>
                    <div class="m-4">
                    <div role="alert" v-if="error" class="alert alert-warning"><strong>Advarsel!</strong> {{ error }}
    </div>
                                              <h4>Finn din bingohall</h4>

                    <div class="row">
                      <div class="col-5 col-lg-2 col-md-3">
                      <div class="form-group input-group"><!----> <div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-map-marker"></i></span></div> <input aria-describedby="addon-right addon-left" placeholder="Postnummer" v-model="postnr" @keyup="postnrKeyUp" axlength="4" class="form-control"> <!---->  <!----></div>
                      </div>
                    <div class="col-6">
                      <button type="button" class="btn btn-icon btn-outline-primary " @click="geoLocation"><span class="btn-inner--icon"><i class="fa fa-map-marker"></i></span> <!----> <span class="btn-inner--text">Min posisjon</span> <!----></button>
                    </div>
                    </div>
                     <bingo-hall v-for="hall in haller" :hall="hall" :key="hall.BingoID" class="mb-2  px-1 px-sm-1 px-md-2 px-lg-3 px-xl-3">test</bingo-hall>

                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>
import axios from '../axios-auth'

export default {
  data () {
    return {
      haller: null,
      error: null,
      postnr: ""
    }
  },
  mounted () {
    if(this.$store.state.ownerFilter > 0) {
      axios
        .post('/halls', { filter: this.$store.state.ownerFilter })
        .then(res => {
            this.haller = res.data.halls
            //console.log(res.data.halls)
            })
    }else{
      axios
        .post('/halls')
        .then(res => {
            this.haller = res.data.halls
            //console.log(res.data.halls)
            })

    }
  

  },
  methods: {

      geoLocation () {
            if(navigator.geolocation){
               // timeout at 60000 milliseconds (60 seconds)
               var options = {timeout:60000};
               navigator.geolocation.getCurrentPosition(this.showPosition, this.errorHandler, options);
            } else{
               console.log("Geolocation ikke mulig");
            }



      },
      errorHandler(err) {

            if(err.code == 1) {
               console.log("Error: Ikke tilgang!");
            }
            else if( err.code == 2) {
               console.log("Error: Possisjon ikke tilgjengelig!");
            }
         },
      showPosition(position) {
        axios
        .post('/halls', { lat: position.coords.latitude, lon: position.coords.longitude, filter: this.$store.state.ownerFilter })
        .then(res => {
          this.haller = res.data.halls
          //console.log(res.data.halls)
          })

    },
    postnrKeyUp() {

        if(this.postnr.length > 3) {
        axios
        .post('/halls', { postnr: this.postnr, filter: this.$store.state.ownerFilter })
        .then(res => {
          this.haller = res.data.halls
          //console.log(res.data.halls)
          })
        }
    }


  }

    
};
</script>
<style>
</style>
